import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Guidelines.css'; // Ensure to create this CSS file for custom styles

const Guidelines = () => {

  return (
    <div className='my-5g' id="container">
    <div className="container my-5" >
      <h2 className="text-center mb-4 guideline-title">Guidelines for Exposition 2024</h2>
      
      <div className="mb-5">
        <h4 className="guideline-subtitle">Rules & Regulations</h4>
        <div className="guideline-card">
          <ul className="list-unstyled">
            <li className="list-item"><span>Articles should be submitted on or before the 17th of November 2024.</span></li>
            <li className="list-item"><span>Submission is limited to registered participants, with a maximum of one article per participant.</span></li>
            <li className="list-item"><span>The specific themes for Exposition 2024 are Management, IT, and Entrepreneurship. Articles must relate to these themes.</span></li>
            <li className="list-item"><span>Articles should be written in English.</span></li>
            <li className="list-item"><span>Word count should be between 1000 and 1500 words.</span></li>
            <li className="list-item"><span>Articles must be written in a chapter or narrative format. Point to point format is not allowed.</span></li>
            <li className="list-item"><span>The content structure should be formal.</span></li>
          </ul>
        </div>
      </div>

      <div className="mb-5">
        <h4 className="guideline-subtitle">Guidelines</h4>
        <div className="guideline-card">
        <p className="guideline-text">Please read and gain a clear understanding of the rules and regulations. If further clarification is required, do not hesitate to contact us through our official platforms.</p>
        <p className="guideline-text">Click below to download the competition guidelines:</p>
        <a href="https://drive.google.com/file/d/1TVqdCXfAOw3C8T-x6fC5sPJlZsB_E5_s/view?usp=sharing" className="btn download-button" target="_blank" 
            rel="noopener noreferrer" >Download Guidelines</a>
      </div>
      </div>
      <div className="mb-5">
        <h4 className="guideline-subtitle">Important</h4>
        <div className="guideline-card">
          <ul className="list-unstyled">
            <li className="list-item"><span>Plagiarism and generating articles using AI are prohibited, although AI can be used for information gathering.</span></li>
            <li className="list-item"><span>Do not copy or reproduce information, facts, or content from the internet. All facts and claims must be verifiable.</span></li>
            <li className="list-item"><span>Articles should present an unbiased representation of viewpoints, initially focusing on the theme.</span></li>
            <li className="list-item"><span>Evaluation criteria include originality, content, structure, language proficiency, and relevance to the theme.</span></li>
            <li className="list-item"><span>The decision of the judges regarding the winning article is final and cannot be contested.</span></li>
            <li className="list-item"><span>Updates, changes, or announcements related to the competition will be posted on our official Social Media Pages (Facebook, Instagram, and LinkedIn).</span></li>
          </ul>
        </div>
      </div>
    </div>
    </div>
  );
};

export default Guidelines;
