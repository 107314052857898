import React from 'react';
import { assets } from '../../assets/assets';
import './Awards.css'; // Import the CSS file for custom styles
import backgroundImage from '../../assets/m2.jpg';

const Awards = () => {
  return (
    <div 
      className="containera" 
      id='containera' 
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        padding: '5rem 0', // Adjust padding as needed
      }}
    >
      <div className="container mt-5">
        <h6 className="text-center mb-4">Awards</h6>
        <div className="row">
          {[
            { img: assets.c1, title: "Winner" },
            { img: assets.c2, title: "1st Runner-Up" },
            { img: assets.c3, title: "2nd Runner-Up" },
            { img: assets.c4, title: "Most Popular" }
          ].map((award, index) => (
            <div className="col-md-3" key={index}>
              <div className="card">
                <img src={award.img} className="card-img-top" alt={`${award.title} Trophy`} />
                <div className="card-body text-center">
                  <h5 className="card-title">{award.title}</h5>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Awards;
