import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { assets } from '../../assets/assets'; // Adjust the path as necessary
import backImage from '../../assets/backimage1.jpg'; // Adjust the path as necessary
import './Header.css';

const Header = () => {
  const [headingSize, setHeadingSize] = useState('2.5rem');
  const [paragraphSize, setParagraphSize] = useState('1.5rem');
  const [logoSize, setLogoSize] = useState('25%');
  const [isVisible, setIsVisible] = useState({
    logo: false,
    heading: false,
    paragraph: false,
  });
  const [headerHeight, setHeaderHeight] = useState('100vh');

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 576 && window.innerWidth >= 322) {
        setHeadingSize('1rem');
        setParagraphSize('0.8rem');
        setHeaderHeight('70vh');
        setLogoSize('55%');
      } else if (window.innerWidth < 322) {
        setHeadingSize('0.8rem');
        setParagraphSize('0.735rem');
        setHeaderHeight('115vh');
        setLogoSize('45%');
      } else if (window.innerWidth < 991.5 && window.innerWidth >= 576.5) {
        setHeadingSize('2rem');
        setParagraphSize('1.2rem');
        setHeaderHeight('70vh');
        setLogoSize('50%');
      } else {
        setHeadingSize('2.5rem');
        setParagraphSize('1.5rem');
        setHeaderHeight('105vh');
        setLogoSize('25%');
      }
    };

    const fadeInDelay = 300;

    setTimeout(() => {
      setIsVisible({
        logo: true,
        heading: true,
        paragraph: true,
      });
    }, fadeInDelay);

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div
    className="text-center"
    id="home"
    style={{
      height: headerHeight,
      marginTop: '56px', // Adjust this value based on your navbar height
      backgroundImage: `url(${backImage})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      color: '#ffffff',
      fontFamily: 'Roboto, sans-serif',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
      <Container fluid style={{ maxWidth: '100%' }}>
        <Row>
          <Col xs={12} md={{ span: 10, offset: 1 }}>
            <Image
              src={assets.edify}
              alt="Edify Logo"
              className={`mb-4 img-fluid ${isVisible.logo ? 'fade-in' : ''}`}
              style={{
                maxWidth: logoSize,
                height: 'auto',
                opacity: isVisible.logo ? 1 : 0,
                transition: 'opacity 1.5s ease-in-out',
              }}
            />
            <h1
              className={`display-5 ${isVisible.heading ? 'fade-in' : ''}`}
              style={{
                fontWeight: '700',
                color: '#c69960',
                fontSize: headingSize,
                opacity: isVisible.heading ? 1 : 0,
                transition: 'opacity 1.5s ease-in-out',
              }}
            >
              INTER-UNIVERSITY ARTICLE COMPETITION
            </h1>
            <p
              className={`lead ${isVisible.paragraph ? 'fade-in' : ''}`}
              style={{
                fontWeight: '500',
                maxWidth: '1500px',
                margin: '0 auto',
                lineHeight: '1.8',
                fontSize: paragraphSize,
                opacity: isVisible.paragraph ? 1 : 0,
                transition: 'opacity 1.5s ease-in-out',
              }}
            >
              Welcome to Edify 2024<br />
              The inter-university article competition organized in conjunction with Exposition Issue 20, Edify aims to foster a connection between academia and industry by showcasing the collective knowledge of undergraduates.
            </p>
            <p
              className={`lead ${isVisible.paragraph ? 'fade-in' : ''}`}
              style={{
                fontWeight: '500',
                maxWidth: '800px',
                margin: '0 auto',
                lineHeight: '1.8',
                fontSize: paragraphSize,
                opacity: isVisible.paragraph ? 1 : 0,
                transition: 'opacity 1.5s ease-in-out',
              }}
            >
              Are you ready to let your creativity captivate the entire industry?<br />
              Edify is where it all begins!
            </p>
            <a
              href="https://bit.ly/Edify_Registrations"
              target="_blank"
              rel="noopener noreferrer"
              className='register-button'
              style={{
                opacity: isVisible.paragraph ? 1 : 0,
                transform: isVisible.paragraph ? 'translateY(0)' : 'translateY(20px)',
                transition: 'opacity 1.5s ease-in-out, transform 1s ease-in-out',
              }}
            >
              Register now
            </a>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Header;
